import useStore from "@/store/store";
import { FiFacebook, FiHome, FiLinkedin, FiMail, FiSend } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import "./Footer.scss";
const Footer = () => {
  const { agence } = useStore((state) => state);
  return (
    <div className="Footer">
      <div className="Footer-top">
        <div className="left">
          <h2>CONTACT</h2>
          {agence?.map((agenceItem) => (
            <div className="agence-detail">
              <p className="title">Agence de {agenceItem.titre}</p>
              <p className="adresse">
                {" "}
                <FiHome className="icon" />
                <span>{agenceItem.adresse}</span>
              </p>
              <p className="adresse">
                {" "}
                <FiMail className="icon" />
                <span>
                  Contact:
                  {agenceItem.adresse}
                </span>
              </p>
            </div>
          ))}
        </div>
        <div className="right">
          <img src="/assets/logo_white.png" alt="Logo en blanc" />
          <div className="message-container">
            <p>Contactez-nous directement</p>
            <div className="input-container">
              <input type="text" placeholder="Nous écrire directement..." />
              <div className="icon-container">
                <FiSend />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer-bottom">
        <ul>
          <li>
            <FiHome className="icon" />
            <p>128ème rue de la Boétie - 75008 PARIS</p>
          </li>
          <li>
            <FiMail className="icon" />
            <p>contact@bigexpansio.eu</p>
          </li>
          <li>
            <FiFacebook className="icon" />
            <p>bigexpansion</p>
          </li>
          <li>
            <FaInstagram className="icon" />
            <p>bigexpansion</p>
          </li>
          <li>
            <FiLinkedin className="icon" />
            <p>
              <a href="https://www.linkedin.com/company/bigexpansion">
                bigexpansion
              </a>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Footer;
