import React, { useEffect, useState, useRef } from "react";
import HTMLFlipBook from "react-pageflip";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./catalogue-detail.scss";
import { catalogues } from "@/utils/content/catalogues";
import { HashLink } from "react-router-hash-link";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc =
  "//unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.js";
type Props = {};

const range = (start: number = 1, end: number) => {
  let res = [];
  for (let i = start; i <= end; i++) {
    res.push(i);
  }
  return res;
};

const CatalogueDetail = (props: Props) => {
  const { id } = useParams();
  const book = useRef();
  const currentCatalogue = catalogues.find((item) => item.type === id);
  if (currentCatalogue === undefined) return <h1>404 NOT FOUND</h1>;
  return (
    <div className="CatalogueDetail">
      <div className="CatalogueDetail-container">
        <div className="CatalogueDetail-container-text">
          <HashLink to="/#catalogues" className="back-btn">
            retour
          </HashLink>
          <h1>{id}</h1>
          {currentCatalogue.title.split("*").map((title) => (
            <h2>{title}</h2>
          ))}
          <p>
            Vous pouvez voir les différents catalogues à propos des services que
            nous pouvons vous délivrer. Le respect du temps de construction et
            la qualité de nos infrastructures combinés à la préservation de la
            nature définissent qui nous sommes
          </p>
        </div>
        <div className="CatalogueDetail-container-book">
          <HTMLFlipBook
            style={{
              backgroundColor: "#D6D6D6",
              boxShadow: "0px 3px 12px rgba(0,0,0,0.8)",
            }}
            width={280}
            height={400}
            onFlip={() => {
              console.log("teste");
            }}
          >
            <div className="homePage">
              <img src="/assets/Logo BIG 2022.png" alt="logo BIG" />
              <h2>
                Bienvenue dans <br /> les catalogues de BIG {id}
              </h2>
              <p>
                Naviguer de page en page pour plus de détail. <br />
                Feuilleter de gauche à droite
              </p>
            </div>
            {range(1, currentCatalogue.page).map((page) => (
              // <div key={page} className="demoPage">
              //   <img
              //     src={`/assets/catalogue/${id}/PAGE ${page}.png`}
              //     alt="catalogue"
              //   />
              // </div>
              <div key={page} className="demoPage">
                <Document
                  file={currentCatalogue.source}
                  onLoadError={(error) => console.error(error)}
                >
                  <Page pageNumber={page} height={400} />
                </Document>
              </div>
            ))}
            <div className="homePage">
              <img src="/assets/Logo BIG 2022.png" alt="logo BIG" />
              <h2>BIG {id}</h2>
              <p>Merci d'avoir lu jusqu'au bout</p>
            </div>
          </HTMLFlipBook>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="CatalogueDetail">
  //     <div className="CatalogueDetail-container">
  //       <div className="CatalogueDetail-container-text">
  //         <HashLink to="/#catalogues" className="back-btn">
  //           retour
  //         </HashLink>
  //         <h1>{id}</h1>
  //         {currentCatalogue.title.split("*").map((title) => (
  //           <h2>{title}</h2>
  //         ))}
  //         <p>
  //           Vous pouvez voir les différents catalogues à propos des services que
  //           nous pouvons vous délivrer. Le respect du temps de construction et
  //           la qualité de nos infrastructures combinés à la préservation de la
  //           nature définissent qui nous sommes
  //         </p>
  //       </div>

  //       <div className="w-[300px] h-[500px]">
  //         <Document
  //           file="/assets/pdf/catalogue_BIG_MULTI.pdf"
  //           onLoadError={(error) => console.error(error)}
  //         >
  //           <HTMLFlipBook
  //             ref={book}
  //             style={{
  //               backgroundColor: "#D6D6D6",
  //               boxShadow: "0px 3px 12px rgba(0,0,0,0.8)",
  //             }}
  //             width={600}
  //             height={550}
  //             onFlip={() => {
  //               console.log("teste");
  //             }}
  //           >
  //             <div ref={React.createRef()}>
  //               <Page pageNumber={1} width={300} height={300} />
  //             </div>
  //             <div ref={React.createRef()}>
  //               <Page pageNumber={2} width={300} height={300} />
  //             </div>
  //             <div ref={React.createRef()}>
  //               <Page pageNumber={3} width={300} height={300} />
  //             </div>
  //           </HTMLFlipBook>
  //         </Document>
  //       </div>
  //       {/* <div className="CatalogueDetail-container-book">
  //       <HTMLFlipBook
  //         style={{
  //           backgroundColor: "#D6D6D6",
  //           boxShadow: "0px 3px 12px rgba(0,0,0,0.8)",
  //         }}
  //         width={300}
  //         height={400}
  //         onFlip={() => {
  //           console.log("teste");
  //         }}
  //       >
  //         <div className="homePage">
  //           <img src="/assets/Logo BIG 2022.png" alt="logo BIG" />
  //           <h2>
  //             Bienvenue dans <br /> les catalogues de BIG {id}
  //           </h2>
  //           <p>
  //             Naviguer de page en page pour plus de détail. <br />
  //             Feuilleter de gauche à droite
  //           </p>
  //         </div>
  //         {range(1, currentCatalogue.page).map((page) => (
  //           <div key={page} className="demoPage">
  //             <img
  //               src={`/assets/catalogue/${id}/PAGE ${page}.png`}
  //               alt="catalogue"
  //             />
  //           </div>
  //         ))}
  //         <div className="homePage">
  //           <img src="/assets/Logo BIG 2022.png" alt="logo BIG" />
  //           <h2>BIG {id}</h2>
  //           <p>Merci d'avoir lu jusqu'au bout</p>
  //         </div>
  //       </HTMLFlipBook>
  //     </div> */}
  //     </div>
  //   </div>
  // );
};

export default CatalogueDetail;

//Version2
