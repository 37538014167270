import AgencesBanner from "@/components/AgencesBanner/AgencesBanner";
import AgencesDetail from "@/components/AgencesDetail/AgencesDetail";
import AgencesMenu from "@/components/AgencesMenu/AgencesMenu";
import { text_banner } from "@/utils/agence";

import styles from "@/styles";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./agences.scss";
type Props = {};

const Agences = (props: Props) => {
  const [showDetail, setShowDetail] = useState(false);
  const [activeMenu, setActiveMenu] = useState(-1);
  return (
    <motion.section id="agences" className={` agences__container`}>
      <div className="self-center lg:hidden flex flex-col items-center">
        <h2 className="text-xl font-medium tracking-wider">NOS AGENCES</h2>
        <p className="text-center">{text_banner}</p>
        <div className="w-1/2 h-[0.5px] bg-grey mt-4" />
        <div className="w-1/3 h-[0.5px] bg-grey mt-2" />
      </div>

      <motion.div
        className={`agences__container-menu ${
          ""
          // showDetail ? ":flex-row" : "flex-col-reverse"
        }`}
      >
        <AnimatePresence>
          {showDetail && (
            <AgencesDetail
              setShowDetail={() => {
                setShowDetail(false);
                setActiveMenu(-1);
              }}
              activateMenu={activeMenu}
              key="box-1"
            />
          )}
          <AgencesMenu
            activateMenu={activeMenu}
            setActiveMenu={(value: number) => {
              if (activeMenu !== -1) {
                setActiveMenu(-1);
              } else {
                setActiveMenu(value);
              }
              console.log(value);
            }}
            setShowDetail={() => {
              setShowDetail((prev) => {
                return !prev;
              });
            }}
            showDetail={showDetail}
          />
          {!showDetail && (
            <AgencesBanner key="box-3" isVisible={showDetail ? false : true} />
          )}
          {/* <hr /> */}
        </AnimatePresence>
      </motion.div>
    </motion.section>
  );
};

export default Agences;
