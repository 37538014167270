import ImgZoom from "@/components/ImgZoom/ImgZoom";
import { useNavigate } from "react-router-dom";
import "./catalogues.scss";
import useStore from "@/store/store";
type Props = {};
const Catalogues = (props: Props) => {
  const { catalogues } = useStore();
  const navigate = useNavigate();
  const goToCatalogueDetail = (type: string) => {
    navigate(`/catalogue/${type}`);
  };
  return (
    <div className="Catalogues" id="catalogues">
      <div className="Catalogues__text-container">
        <h2>{catalogues?.titre}</h2>
        <p>{catalogues?.sousTitre}</p>
      </div>
      <div className="Catalogues__image-container">
        <ImgZoom
          src="/assets/catalogue/MULTI/PAGE 1.png"
          alt="BIG MULTI"
          className="Catalogues__image-container-picture"
        />
        <div className="catalogueCover">
          <h3>BIG MULTI</h3>
          <button onClick={() => goToCatalogueDetail("MULTI")}>
            VOIR DETAIL
          </button>
        </div>
      </div>
      <div className="Catalogues__image-container">
        <ImgZoom
          src="/assets/catalogue/PROXI/PAGE 1.png"
          alt="BIG MULTI"
          className="Catalogues__image-container-picture"
        />
        <div className="catalogueCover">
          <h3>BIG PROXI</h3>
          <button onClick={() => goToCatalogueDetail("PROXI")}>
            VOIR DETAIL
          </button>
        </div>
      </div>
      <div className="Catalogues__image-container">
        <ImgZoom
          src="/assets/catalogue/PLATEAU/PAGE 1.png"
          alt="BIG MULTI"
          className="Catalogues__image-container-picture"
        />
        <div className="catalogueCover">
          <h3>BIG PLATEAU</h3>
          <button onClick={() => goToCatalogueDetail("PLATEAU")}>
            VOIR DETAIL
          </button>
        </div>
      </div>
    </div>
  );
};
export default Catalogues;
