import sanityClient, { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const SanityClient = createClient({
  projectId: "ktdwfzl9", // find this at manage.sanity.io or in your sanity.json
  dataset: "big", // this is from those question during 'sanity init'
  apiVersion: "2023-12-28",
  token: process.env.REACT_APP_SANITY_KEY,
  useCdn: false,
  ignoreBrowserTokenWarning: true,
});
const builder = imageUrlBuilder(SanityClient);

export function urlForImage(source) {
  return builder.image(source);
}
export default SanityClient;
