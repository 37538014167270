import sanityClient from "@/client";


 const getAccueil = async () => {
    const accueil = await sanityClient.fetch(`*[_type == "accueil"][0]{
        titre,
        paragraphe1,
        paragraphe2,
    }`);
    return accueil;
}

const getAgenceEnGenerale = async () => {
    const agenceEnGenerale = await sanityClient.fetch(`*[_type == "agenceEnGenerale"][0]{
        titre,
        description,
    }`);
    return agenceEnGenerale;
}

const getAgence = async () => {
    const agence = await sanityClient.fetch(`*[_type == "agence"]{
        titre,
        adresse,
        contact,
        imageCouverture,
        apropos,
        mission,
        competence,
        references
    }`);
    return agence;
}

const getReference = async () => {
    const reference = await sanityClient.fetch(`*[_type == "references"][0]{
        titre,
        sousTitre,
    }`);
    return reference;
}

const getCatalogue = async () => {
    const catalogue = await sanityClient.fetch(`*[_type == "catalogues"][0]{
        titre,
        sousTitre,
    }`);
    return catalogue;
}

const getEquipe = async () => {
    const equipes = await sanityClient.fetch(`*[_type == "equipes"]{
        nom,
        poste,
        profileUrl,
        descriptionPoste
    }`);
    return equipes;
}



const getEcoMateriaux = async () => {
    const ecoMateriaux = await sanityClient.fetch(`*[_type == "ecoMateriauxInfo"][0]{
        titre,
        sousTitre,
        liste,
    }`);
    return ecoMateriaux;
}


const getEcoCitoyens = async () => {
    const ecoCitoyens = await sanityClient.fetch(`*[_type == "ecoCitoyens"][0]{
        titre,
        sousTitre,
        liste,
    }`);
    return ecoCitoyens;
}

const getEcoChantiers = async () => {
    const ecoCitoyens = await sanityClient.fetch(`*[_type == "ecoChantiers"][0]{
        titre,
        liste,
    }`);
    return ecoCitoyens;
}



export const getMainContexte = async () => {
    
    const [accueil, agenceEnGenerale, agence, reference, catalogues, equipes, ecoMateriaux, ecoCitoyens, ecoChantiers] = await Promise.all([
        getAccueil(),
        getAgenceEnGenerale(),
        getAgence(),
        getReference(),
        getCatalogue(),
        getEquipe(),
        getEcoMateriaux(),
        getEcoCitoyens(),
        getEcoChantiers()
    ]);
    console.log("accueil", accueil.titre);
    return {accueil, agenceEnGenerale, agence, referenceEnGenerale: reference, catalogues, equipes, ecoMateriaux, ecoCitoyens, ecoChantiers};
}


