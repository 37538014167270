const heroTitle = `PENSER, IMAGINER ET CONCEVOIR\nPOUR PRESERVER DEMAIN`;

const heroText1 = `
Nous sommes un cabinet d'ingénieurie 
spécialisé dans la conception d'équipement 
et d'aménagements sportifs, urbains, culturels 
et touristiques.
`;

const heroText2 = `
B.I.G dessine, conçoit et réalise des installations 
innovantes et durables, inscrites dans un 
écosystème urbain responsable
`;

export { heroTitle, heroText1, heroText2 };
