import {
  Hero,
  Agences,
  References,
  ReferenceDetail,
  Catalogues,
  CatalogueDetail,
  Equipes,
  EcoMateriaux,
  EcoCitoyens,
  EcoChantiers,
  Footer,
} from "./containers";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import CircleNav from "./components/CircleNav/CircleNav";
import { useQuery } from "react-query";
import { getMainContexte } from "@/api/api";

import useStore from "./store/store";

function App() {
  const { initData } = useStore();

  const { data, isLoading, isError } = useQuery(
    "globalDatas",
    getMainContexte,
    {
      onSuccess: (data) => {
        initData(data);
      },
    }
  );

  const location = useLocation();
  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error...</div>;
  if (
    data?.accueil &&
    data.agence &&
    data.agenceEnGenerale &&
    data.catalogues &&
    data.equipes &&
    data.referenceEnGenerale &&
    data.ecoMateriaux &&
    data.ecoCitoyens &&
    data.ecoChantiers
  ) {
    return (
      // <Suspense fallback={<div>Loading...</div>}>
      <AnimatePresence mode="wait">
        <Routes key={location.pathname} location={location}>
          <Route
            path="/"
            element={
              <motion.div
                className="App"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ diration: 3 }}
              >
                <CircleNav />
                <Hero />
                <Agences />
                <References />
                <EcoMateriaux />
                <EcoCitoyens />
                <EcoChantiers />
                <Catalogues />
                <Equipes />
                <Footer />
              </motion.div>
            }
          />
          <Route path="/reference/:id" element={<ReferenceDetail />} />
          <Route path="/catalogue/:id" element={<CatalogueDetail />} />
        </Routes>
      </AnimatePresence>
      // </Suspense>
    );
  } else return <div>no data</div>;
}

export default App;
