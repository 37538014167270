import useStore from "@/store/store";
import "./EcoCitoyens.scss";
import { listes } from "./EcoCitoyens.utils";
const EcoCitoyens = () => {
  const { ecoCitoyens } = useStore();

  return (
    <div className="EcoCitoyens">
      <div className="EcoCitoyens-content">
        <h2>
          <span className="letter">
            {ecoCitoyens &&
              Array.from(ecoCitoyens?.titre).map((letter, index) => (
                <span key={index} className="letter">
                  {letter}
                </span>
              ))}
          </span>
        </h2>
        <p>{ecoCitoyens?.sousTitre}</p>
        <ul className="EcoCitoyens-listes">
          {ecoCitoyens?.liste.map((item, index) => {
            return (
              <li key={index}>
                <h3>{item.titre}</h3>
                <p>{item.description}</p>
              </li>
            );
          })}
        </ul>
      </div>
      <img
        src="/assets/EcoCitoyens-illustration.png"
        alt="EcoCitoyens illustration"
      />
    </div>
  );
};
export default EcoCitoyens;
