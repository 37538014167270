import { FaHome } from "react-icons/fa";
import { MdPlace, MdWork } from "react-icons/md";
import { IoIosLeaf, IoMdPhonePortrait } from "react-icons/io";
import { AiFillBook } from "react-icons/ai";

export const nav = [
  {
    icon: <FaHome className="hero__container-header__nav-item-icon" />,
    title: "Accueil",
  },
  {
    icon: <MdPlace className="hero__container-header__nav-item-icon" />,
    title: "Agences",
  },
  {
    icon: <MdWork className="hero__container-header__nav-item-icon" />,
    title: "Références",
  },
  {
    icon: <IoIosLeaf className="hero__container-header__nav-item-icon" />,
    title: "Eco responsable",
  },
  {
    icon: <AiFillBook className="hero__container-header__nav-item-icon" />,
    title: "Catalogues",
  },
  {
    icon: (
      <IoMdPhonePortrait className="hero__container-header__nav-item-icon" />
    ),
    title: "Contacts",
  },
];
