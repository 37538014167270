import React, { useState } from "react";
import ImgZoom from "../ImgZoom/ImgZoom";
import "./reference-detail-projection.scss";
import { HashLink } from "react-router-hash-link";
type Props = {
  projections: { id_projection: number; photos: string[] }[];
};
const ReferenceDetailProjection = ({ projections }: Props) => {
  const [active, setActive] = useState(0);
  return (
    <div className="ReferenceDetailProjection">
      <h2>Projection</h2>
      <div className="ReferenceDetailProjection-phaseContainer">
        {projections.map((phase) => (
          <p
            key={phase.id_projection}
            className={`${active === phase.id_projection - 1 ? "active" : ""}`}
            onClick={() => setActive(phase.id_projection - 1)}
          >
            PHASE {phase.id_projection}
          </p>
        ))}
      </div>
      <div className="ReferenceDetailProjection-illustrationContainer">
        {projections[active].photos.map((photo, index) => (
          <ImgZoom key={index} src={photo} alt={`illustration ${index}`} />
        ))}
      </div>
      <HashLink className="backBtn" to="/#references">
        retour
      </HashLink>
    </div>
  );
};
export default ReferenceDetailProjection;
