import "./reference-detail-content.scss";
import { FiMail, FiLinkedin, FiFacebook } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
type Props = {
  year: number;
  prix: string;
  titre: string;
  description: string;
};
const ReferenceDetailContent = ({ description, prix, titre, year }: Props) => {
  return (
    <div className="ReferenceDetailContent">
      <div className="ReferenceDetailContent-yearPriceContainer">
        <p className="ReferenceDetailContent-yearPriceContainer__year">
          Année: <span>{year}</span>
        </p>
        <p className="ReferenceDetailContent-yearPriceContainer__prix">
          Prix: <span>{prix}£</span>
        </p>
      </div>
      <h2 className="ReferenceDetailContent__titre">{titre}</h2>
      <p className="ReferenceDetailContent__description">{description}</p>
      <div className="ReferenceDetailContent__socialMedia">
        <FaInstagram className="social-icon" />
        <FiFacebook className="social-icon" />
        <FiLinkedin className="social-icon" />
        <FiMail className="social-icon" />
      </div>
      <HashLink className="backBtn" to="/#references">
        retour
      </HashLink>
    </div>
  );
};
export default ReferenceDetailContent;
