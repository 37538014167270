export const catalogues = [
  {
    type: "MULTI",
    page: 8,
    title: "Equipements & Installations*MULTI-SPORTS",
    source: "/assets/pdf/catalogue_BIG_MULTI.pdf",
  },
  {
    type: "PROXI",
    page: 7,
    title: "STADES DE PROXIMITE*ATHLETISME/FOOTBALL",
    source: "/assets/pdf/Catalogue BIG PROXI.pdf",
  },
  {
    type: "PLATEAU",
    page: 5,
    title: "PLATEAU SPORTIF:*BASKET/VOLLEY*HAND/FUTSAL",
    source: "/assets/pdf/catalogue BIG PLATEAU.pdf",
  },
];
