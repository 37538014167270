import { motion } from "framer-motion";
import { agences } from "@/utils/agence";
import "./agencesDetail.scss";
import { Agence } from "@/interfaces/Agence";
import useStore from "@/store/store";
import { MdClose } from "react-icons/md";

interface AgencesDetailProps {
  setShowDetail: () => void;
  activateMenu: number;
}

const AgencesDetail = ({ activateMenu, setShowDetail }: AgencesDetailProps) => {
  const detailAgence = (useStore((state) => state)?.agence as Agence[])[
    activateMenu
  ];

  return (
    <motion.div
      layout="position"
      // transition={{ duration: 0.3 }}
      initial={{ opacity: 0, scaleX: 0 }}
      animate={{ opacity: 1, scaleX: 1 }}
      // exit={{ opacity: 0, scaleX: 0 }}
      transition={{ duration: 0.3 }}
      className="agences__container-menu__detail relative"
    >
      <div
        className="sm:hidden absolute right-4 top-12"
        onClick={() => setShowDetail()}
      >
        <MdClose size={28} />
      </div>
      <h1 className={`agences__container-menu__detail-title`}>
        Bienvenue
        <img src={agences[activateMenu].flagImg} />
        <br />
        Agence {activateMenu !== -1 && detailAgence.titre}
      </h1>
      <section>
        <h2>A PROPOS DE NOUS</h2>
        <p>
          {detailAgence.apropos.map((paragraph, index) => (
            <span key={`paragraph_${index}`}>{paragraph}</span>
          ))}
        </p>
      </section>
      <section>
        <h2>NOS MISSIONS</h2>
        <div className="agences__container-menu__detail-mission">
          {detailAgence.mission.map((item, index) => (
            <div
              key={index}
              className="agences__container-menu__detail-mission__item"
            >
              <h3>{item.titreMission}</h3>
              <p>
                {item.descriptionMission ||
                  `Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Magnam, quia iure praesentium exercitationem minus velit id
                voluptatum dolorem corporis neque!`}
              </p>
            </div>
          ))}
        </div>
      </section>
      <section>
        <h2>NOS COMPETENCES</h2>
        <div className="agences__container-menu__detail-competence">
          <p>
            {detailAgence.competence.split(",").map((item, index) => (
              <span key={index}>{item}</span>
            ))}
          </p>
        </div>
      </section>
    </motion.div>
  );
};

export default AgencesDetail;
