import React from "react";
import { useParams } from "react-router-dom";
import "./reference-detail.scss";
import { motion } from "framer-motion";
import ReferenceDetailVideoPlayer from "@/components/ReferenceDetailVideoPlayer/ReferenceDetailVideoPlayer";
import ReferenceDetailContent from "@/components/ReferenceDetailContent/ReferenceDetailContent";
import ReferenceDetailProjection from "@/components/ReferenceDetailProjection/ReferenceDetailProjection";
import { SingletonReferences } from "@/utils/references_singleton";
import { Reference } from "@/interfaces/reference";
type Props = {};
const ReferenceDetail = (props: Props) => {
  const { id } = useParams();
  const detailReference = SingletonReferences.getReferences().find(
    (item) => item.id === id
  ) as Reference;
  console.log(detailReference);
  return (
    <motion.div
      className="ReferenceDetail w-full h-screen bg-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* <div className="ReferenceDetail-videoPlayer"> */}
      <ReferenceDetailVideoPlayer
        pays={detailReference.pays}
        videoLink={detailReference.urlVideo}
      />
      {/* </div> */}
      {/* <div className="ReferenceDetail-contentContainer"></div> */}
      <ReferenceDetailContent
        year={detailReference.year}
        prix={`${detailReference.prix} ${detailReference.unite}`}
        description={detailReference.description}
        titre={detailReference.titre}
      />
      {/* <div className="ReferenceDetail-projectionContainer"></div> */}
      <ReferenceDetailProjection projections={detailReference.projection} />
    </motion.div>
  );
};
export default ReferenceDetail;
