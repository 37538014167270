import {create} from "zustand";
import { IStore } from "./store.type";


const useStore = create<IStore>()((set) => ({
    initData(data) {
        set((state => ({...data})))
    },
}))


export default useStore;