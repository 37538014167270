import useStore from "@/store/store";
import "./EcoChantiers.scss";
import { listes } from "./EcoChantiers.utils";
const EcoChantiers = () => {
  const { ecoChantiers } = useStore();

  return (
    <div className="EcoChantiers">
      <img
        className="leaf-illustration"
        src="/assets/ILLUSTRATION_2.png"
        alt="Eco chantier illustration"
      />
      <h2>
        <span>
          {ecoChantiers &&
            Array.from(ecoChantiers?.titre).map((letter, index) => (
              <span key={index} className="letter">
                {letter}
              </span>
            ))}
        </span>
      </h2>
      <ul className="EcoChantiers-content">
        {ecoChantiers?.liste.map((item, index) => {
          return (
            <li key={index}>
              <p>
                <span>{item.titre}</span>
              </p>
              <div className="separator">
                <div className="bulle"></div>
              </div>
              <p>{item.description}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default EcoChantiers;
