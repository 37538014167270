import { Reference } from "@/interfaces/reference";
import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import ImgZoom from "../ImgZoom/ImgZoom";
import "./references-item.scss";
type Props = {
  reference: Reference;
};
const ReferencesItem = forwardRef<any, Props>(({ reference }, ref) => {
  const navigate = useNavigate();
  const openDetail = () => {
    navigate(`/reference/${reference.id}`);
  };
  return (
    <div
      onClick={openDetail}
      className="ReferencesItem"
      id={`reference-${reference.id}`}
      ref={ref}
    >
      <ImgZoom
        src={reference.urlImgCover}
        alt="references"
        className="ReferencesItem-image"
      />
      <div className="ReferencesItem-subTitle__container">
        <button>{reference.pays}</button>
        <h5>
          {reference.prix} {reference.unite}€
        </h5>
      </div>
      <h4>{reference.titre}</h4>
      <p>{reference.description}</p>
    </div>
  );
});
export default ReferencesItem;
