import { useEffect, useRef } from "react";
import "./references.scss";
import { MdWork } from "react-icons/md";
import ReferencesItem from "@/components/ReferencesItem/ReferencesItem";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { SingletonReferences } from "@/utils/references_singleton";
import useStore from "@/store/store";

type Props = {};
const References = (props: Props) => {
  const { referenceEnGenerale } = useStore();
  let currentRefIndex = 0;
  const referenceItemRef = useRef<HTMLDivElement[]>([]);
  const references = SingletonReferences.getReferences();

  const handleRightArrowClick = () => {
    currentRefIndex += 3;
    if (currentRefIndex > references.length) currentRefIndex = 0;
    else if (currentRefIndex === references.length)
      currentRefIndex = references.length - 1;

    console.log(referenceItemRef.current[currentRefIndex]);
    referenceItemRef.current[currentRefIndex].scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleLeftArrowClick = () => {
    currentRefIndex -= 3;
    console.log(currentRefIndex);
    if (currentRefIndex < -2) currentRefIndex = references.length - 1;
    else if (currentRefIndex >= -3 && currentRefIndex < 0) currentRefIndex = 0;
    referenceItemRef.current[currentRefIndex].scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    referenceItemRef.current = referenceItemRef.current.slice(
      0,
      references.length
    );
  }, []);

  return (
    <div className="References" id="references">
      <h2>
        <MdWork size={"36px"} />
        {referenceEnGenerale?.titre}
      </h2>
      <p>{`${referenceEnGenerale?.sousTitre}`}</p>
      <div className="relative mt-14 w-full px-4">
        <FaCaretLeft
          onClick={handleLeftArrowClick}
          className="hidden sm:block absolute text-4xl top-1/2 -left-6 hover:text-primary cursor-pointer"
        />
        <div className="References-item__container">
          {references.map((item, i) => (
            <ReferencesItem
              key={item.id}
              reference={item}
              ref={(el) => (referenceItemRef.current[i] = el)}
            />
          ))}
        </div>
        <FaCaretRight
          onClick={handleRightArrowClick}
          className=" hidden sm:block absolute text-4xl top-1/2 -right-6 hover:text-primary cursor-pointer"
        />
      </div>
    </div>
  );
};
export default References;
