import { bannerImg } from "@/utils/agence";
import { text_banner } from "@/utils/agence";
import { animateAgence } from "@/utils/agence_motion";
import { motion, AnimatePresence } from "framer-motion";
import "./agencesBanner.scss";
import useStore from "@/store/store";
type Props = {};

const AgencesBanner = ({ isVisible }: { isVisible: boolean }) => {
  const { agenceEnGenerale } = useStore();

  return (
    <motion.div
      layout="position"
      initial={{ scaleX: 0 }}
      animate={{ scaleX: 1 }}
      // exit={{ scaleX: 0 }}
      transition={{ duration: 0.3, ease: "linear" }}
      className="agences__container-menu__banner"
    >
      <img src="/assets/agence_banner_1.jpg" />
      <div className="agences__container-menu__banner-content">
        <h2>{agenceEnGenerale?.titre}</h2>
        <p>{agenceEnGenerale?.description}</p>
        <button>
          <span>Contacter nous directement</span>
        </button>
      </div>
    </motion.div>
  );
};

export default AgencesBanner;
