import React, { useEffect, useRef, useState } from "react";
import "./circle-nav.scss";
import { FiMenu } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";
import { IoMdHome, IoMdClose, IoIosLeaf } from "react-icons/io";
import { MdPlace, MdWork, MdContacts } from "react-icons/md";
import { ImBooks } from "react-icons/im";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
type Props = {};
const CircleNav = (props: Props) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    if (expand === false)
      setTimeout(() => {
        buttonRef.current?.classList.add("opacity-30");
      }, 2000);
  }, [expand]);
  return (
    <div ref={buttonRef} className={`CircleNav ${expand ? "expand" : ""}`}>
      <div className="w-full h-full relative">
        <AnimatePresence>
          {!expand ? (
            <motion.div
              key="OpenMenu"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="item z-10"
              onClick={() => setExpand((prev) => !prev)}
            >
              <FiMenu className="icon" color="#2A3FCC" />
            </motion.div>
          ) : (
            <motion.div
              key="CloseMenu"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="item z-10"
              onClick={() => setExpand((prev) => !prev)}
            >
              <IoMdClose className="icon" />
            </motion.div>
          )}
        </AnimatePresence>
        <div className="item item-1">
          <a href="#hero">
            <IoMdHome className="icon" />
          </a>
        </div>
        <div className="item item-2">
          <a href="#agences">
            <MdPlace className="icon" />
          </a>
        </div>
        <div className="item item-3">
          <a href="#references">
            <MdWork className="icon" />
          </a>
        </div>
        <div className="item item-4">
          <a href="#">
            <IoIosLeaf className="icon" />
          </a>
        </div>
        <div className="item item-5">
          <a href="#catalogues">
            <ImBooks className="icon" />
          </a>
        </div>
        <div className="item item-6">
          <a href="#equipes">
            <FaUsers className="icon" />
          </a>
        </div>
        <div className="item item-7">
          <a href="#">
            <MdContacts className="icon" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default CircleNav;
