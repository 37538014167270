import { Equipe } from "@/interfaces/Equipe";
import React, { useState } from "react";
import ImgZoom from "../ImgZoom/ImgZoom";
import "./equipes-item.scss";
import { AnimatePresence, motion } from "framer-motion";
import { urlForImage } from "@/client";

type Props = {
  equipe: Equipe;
};
const EquipesItem = ({ equipe }: Props) => {
  const [expand, setIsExpand] = useState(false);
  return (
    <div
      onClick={() => setIsExpand((prev) => !prev)}
      className={`EquipesItem ${expand ? "isExpand" : ""}`}
    >
      <ImgZoom
        src={urlForImage(equipe.profileUrl).url()}
        alt="profile membre"
        className="EquipesItem-profile"
      />
      <AnimatePresence mode="wait">
        <div
          className={`EquipesItem-content px-4 ${
            expand ? "justify-start" : "justify-end"
          }`}
        >
          <motion.h2
            className={`${expand ? " w-full " : "-top-12"}`}
            animate={{ rotate: expand ? "0deg" : "-90deg" }}
            transition={{
              type: "spring",
            }}
          >
            {equipe.nom.split(" ").map((item, index) => (
              <span
                key={index}
                className={`${
                  !expand ? (index !== 0 ? "hidden" : "visible") : ""
                }`}
              >
                {item}
              </span>
            ))}
          </motion.h2>
          {expand && (
            <motion.div
              key="content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="EquipesItem-content__main"
            >
              <p className="EquipesItem-content__main-poste">
                Post: <span>{equipe.poste}</span>
              </p>
              <motion.p
                initial={{ x: -200, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                className="EquipesItem-content__main-descriptionPoste"
              >
                {equipe.descriptionPoste}
              </motion.p>
              <motion.p
                initial={{ x: -200, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                className="EquipesItem-content__main-descriptionPoste"
              >
                N'hésitez pas à faire appel à B.I.G pour vos prochaines projets.
                Vous allez avoir un résultat plus que satisfaisant
              </motion.p>
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </div>
  );
};
export default EquipesItem;
