import React from "react";
import "./Equipes.scss";
import { FiUsers } from "react-icons/fi";
// import { equipes } from "@/utils/content/equipe";
import EquipesItem from "@/components/EquipesItem/EquipesItem";
import useStore from "@/store/store";

type Props = {};
const Equipes = (props: Props) => {
  const { equipes } = useStore();
  return (
    <div className="Equipes" id="equipes">
      <FiUsers className="Equipes-icon" strokeWidth={1.2} />
      <h2 className="Equipes-title">Notre équipe</h2>
      <p className="Equipes-subtitle">
        L'équipe de B.I.G est constitué par des ingénieurs et experts pour
        <br />
        le bon accomplissements de
        <br />
        vos besoins
      </p>
      <div className="Equipes-list">
        {equipes?.map((membre, index) => (
          <EquipesItem equipe={membre} key={index} />
        ))}
      </div>
    </div>
  );
};
export default Equipes;
