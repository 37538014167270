import "./agencesMenu.scss";
import { agences } from "@/utils/agence";
import { motion } from "framer-motion";

import useStore from "@/store/store";
type Props = {
  setShowDetail: () => void;
  setActiveMenu: (value: number) => void;
  activateMenu: number;
  showDetail: boolean;
};

const box2Anim = {
  hidden: {
    opacity: 0,
    scaleX: 0,
    transition: {
      delay: 0,
      ease: "linear",
    },
  },
  exit: {
    opacity: 0,
    scaleX: 0,
    transition: {
      delay: 0,
      ease: "linear",
    },
  },
  visible: {
    opacity: 1,
    scaleX: 1,
    transition: {
      delay: 0,
      ease: "linear",
    },
  },
};

const AgencesMenu = ({
  setShowDetail,
  showDetail,
  setActiveMenu,
  activateMenu,
}: Props) => {
  const { agence } = useStore();

  return (
    <motion.div
      layout="position"
      transition={{
        delay: 0,
        type: "keyframes",
      }}
      variants={box2Anim}
      initial="hidden"
      animate="visible"
      exit="exit"
      // key="box-2"
      className={`agences__container-menu__left ${
        showDetail ? "hidden sm:grid" : "grid"
      }`}
    >
      {agence?.map((item, index) => (
        <div
          key={item.titre + " " + index}
          className={`agences__container-menu__left-item ${
            activateMenu !== -1 && activateMenu !== index
              ? "opacity-50 cursor-pointer"
              : activateMenu !== -1
              ? "border-2 rounded-2xl"
              : ""
          }`}
          onClick={() => {
            if (showDetail === true) {
              if (activateMenu === index) {
                setActiveMenu(index);

                setShowDetail();
              }
            } else {
              setActiveMenu(index);
              setShowDetail();
            }
          }}
        >
          <img src={agences[index].img} alt={`agence ${item.titre}`} />
          <div className="agences__container-menu__left-item__container">
            <h2>Agence de {item.titre}</h2>
            <p>Découvrez-nous:</p>
            <p>{item.adresse}</p>
            <p>Contact: {item.contact}</p>
            <button>Consulter</button>
          </div>
        </div>
      ))}
    </motion.div>
  );
};

export default AgencesMenu;
