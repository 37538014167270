import { FaInstagram } from "react-icons/fa";
import { FiFacebook, FiLinkedin, FiMail } from "react-icons/fi";
const socialIcons = [
  <FaInstagram className="icons" size="2rem" />,
  <FiFacebook className="icons" size="2rem" />,
  <FiLinkedin className="icons" size="2rem" />,
  <FiMail className="icons" size="2rem" />,
];

export { socialIcons };
