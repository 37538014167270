export const agences = [
  {
    titre: "Mayotte",
    adresse: "6 rue des Baboul Baraka MBOUINI - 97625 KANI-KELI MAYOTTE",
    contact: "agence976@bigexpansion.eu",
    img: "/assets/REFERENCE_YT.jpg",
    flagImg: "/assets/flag_yt.png",

    apropos: [
      `Notre agence dispose de toutes les compétences de maitrise 
        d'oeuvre de conception et de realisation dans le domaine sportif,
        culturel et touristique.
        `,
      `
        Ingénieurs généralistes et de structure, architectes, économistes,
        techniciens… tous spécialisés dans le diagnostic,  la conception et 
        le pilotage d'opérations.
        `,
      `
        Spécificité de concevoir des équipements en zone tropicale, 
        sismique et à forts aléas climatiques
        `,
    ],
    mission: [
      {
        titreMission: "MAITRISE D'OEUVRE DE CONCEPTION  ET DE REALISATION",
        descriptionMission: "descriptionMission",
      },
      {
        titreMission: "ORDONNANCEMENT ET PILOTAGE",
        descriptionMission: "descriptionMission",
      },
      {
        titreMission: "INGENIERIE EN ZONE TROPICAL",
        descriptionMission: "descriptionMission",
      },
      {
        titreMission: "PROGRAMMES",
        descriptionMission: "descriptionMission",
      },
      {
        titreMission: "ASSISTANCE A MAITRISE D OUVRAGE",
        descriptionMission: "descriptionMission",
      },
      {
        titreMission: "FAISABILITES",
        descriptionMission: "descriptionMission",
      },
      {
        titreMission: "RECHERCHES DE SUBVENTIONS",
        descriptionMission: "descriptionMission",
      },
    ],
    competence: ` 
        Expertise et ingénierie spécialisée 
      en équipements sportifs, urbains, culturels et touristiques
      Ingénieurie en structure 
      Conception spécialisée en zone 
      tropicale, sismique et à forts aléas climatiques
      Economie de la construction
      Parfaite connaissances des réglementations féférales,
      Conduite d'opérations, pilotage OPC,
      Marchés publics,
      Conception environnementale et éco-responsable,
      Recherche de subventions...
      `,

    references: [
      {
        id: 1,
        urlImgCover: "/assets/agence_banner_3.png",
        urlVideo: "/assets/video/e-embauche.mp4",
        pays: "KOUNGOU",
        titre: "STADE ATHLETIQUE DE LONGONI",
        description:
          "création complète d'un stade athlétique 8 couloirs. construction d'un bâtiment vestiaires tribunes couvertes. Accessibilité du public et stationnement 80 000 m3 de terrassement sur 27 000 m2",
        prix: 15,
        unite: "M",
        year: 2018,
        projection: [
          {
            id_projection: 1,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_1.jpg",
            ],
          },
          {
            id_projection: 2,
            photos: [
              "/assets/agence_banner_1.jpg",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_1.jpg",
            ],
          },
          {
            id_projection: 3,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_1.jpg",
            ],
          },
          {
            id_projection: 4,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_1.jpg",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_1.jpg",
              "/assets/agence_banner_3.png",
            ],
          },
        ],
      },
      {
        id: 2,
        urlImgCover: "/assets/agence_banner_3.png",
        urlVideo: "/assets/video/e-embauche.mp4",
        pays: "KOUNGOU",
        titre: "STADE ATHLETIQUE DE LONGONI",
        description:
          "création complète d'un stade athlétique 8 couloirs. construction d'un bâtiment vestiaires tribunes couvertes. Accessibilité du public et stationnement 80 000 m3 de terrassement sur 27 000 m2",
        prix: 15,
        unite: "M",
        year: 2018,
        projection: [
          {
            id_projection: 1,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
          {
            id_projection: 2,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
          {
            id_projection: 3,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
        ],
      },
    ],
  },
  {
    titre: "Versaille",
    adresse: "20 rue des Bourdonnais - 78000 VERSAILLES",
    contact: "contact@bigexpansion.eu",
    img: "/assets/REFERENCE_FR.jpg",
    flagImg: "/assets/flag_fr.png",

    apropos: [
      `Notre société dispose de toutes les compétences 
        de maitrise d'oeuvre de conception et de realisation 
        dans le domaine sportif.
        
        `,
      `
          BIG expansion a développé un véritable 
          "pôle d'équipements sportifs"

        `,
      `
        Ingénieurs généralistes et de structure, architectes, 
        économistes, techniciens... tous 
        spécialisés dans le diagnostic, la conception et le 
        pilotage d'opérations
        `,
    ],
    mission: [
      {
        titreMission: "MAITRISE D'OEUVRE DE CONCEPTION  ET DE REALISATION",
      },
      {
        titreMission: "ORDONNANCEMENT ET PILOTAGE",
      },

      {
        titreMission: "PROGRAMMES",
      },
      {
        titreMission: "FAISABILITES",
      },
      {
        titreMission: "ASSISTANCE A MAITRISE D OUVRAGE",
      },
    ],
    competence: ` 
      Expertise et ingénierie spécialisée en équipements sportifs,
      Ingénierie spécialisée en aménagements VRD,
      Ingénierie spécialisée en structure,
      Economie de la construction
      Accessibilité ERP,
      
      Parfaite connaissances des réglementations féférales,
      Conduite d'opérations, pilotage OPC,
      Marchés publics,
      Conception environnementale et éco-responsable,
      Recherche de subventions...
      `,

    references: [
      {
        id: 1,
        urlImgCover: "/assets/agence_banner_3.png",
        urlVideo: "/assets/video/e-embauche.mp4",
        pays: "KOUNGOU",
        titre: "STADE ATHLETIQUE DE LONGONI",
        description:
          "création complète d'un stade athlétique 8 couloirs. construction d'un bâtiment vestiaires tribunes couvertes. Accessibilité du public et stationnement 80 000 m3 de terrassement sur 27 000 m2",
        prix: 15,
        unite: "M",
        year: 2018,
        projection: [
          {
            id_projection: 1,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
          {
            id_projection: 2,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
          {
            id_projection: 3,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
        ],
      },
      {
        id: 2,
        urlImgCover: "/assets/agence_banner_3.png",
        urlVideo: "/assets/video/e-embauche.mp4",
        pays: "KOUNGOU",
        titre: "STADE ATHLETIQUE DE LONGONI",
        description:
          "création complète d'un stade athlétique 8 couloirs. construction d'un bâtiment vestiaires tribunes couvertes. Accessibilité du public et stationnement 80 000 m3 de terrassement sur 27 000 m2",
        prix: 15,
        unite: "M",
        year: 2018,
        projection: [
          {
            id_projection: 1,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
          {
            id_projection: 2,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
          {
            id_projection: 3,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
        ],
      },
    ],
  },
  {
    titre: "Madagascar",
    adresse:
      "Lot H3 Immeuble BATIMAD Ankorondrano ANTANANARIVO 101 - MADAGASCAR",
    contact: "agence101@bigexpansion.eu",
    img: "/assets/REFERENCE_MG.jpg",
    flagImg: "/assets/flag_mg.png",

    apropos: [
      `
        B.I.G. Expansion Agence 101, entreprise générale de travaux
        spécialisée dans la conception et réalisation d’équipements et
        d’aménagements sportifs, urbains, culturels et touristiques est
        responsable de la gestion complète de vos projets de construction ou
        de rénovation, de la phase de conception à la livraison clé en main.
        `,
      `
        B.I.G dessine, conçoit et réalise des installations uniques tout en
        gardant à l’esprit les objectifs de ses clients.
        `,
      `
        Chargés d’études, architectes, ingénieurs, paysagistes, géomètres,
        technico- commerciaux, graphistes, logisticiens, chefs de projet, 
        conducteurs de travaux, et techniciens…, hommes et femmes de 
        terrain allient leurs compétences pour apporter des solutions         
        `,
    ],
    mission: [
      {
        titreMission: "CONCEPTION",
      },
      {
        titreMission: "ORDONNANCEMENT ET PILOTAGE",
      },

      {
        titreMission: "PROGRAMMES",
      },
      {
        titreMission: "FAISABILITES",
      },
      {
        titreMission: "ASSISTANCE A MAITRISE D OUVRAGE",
      },
      {
        titreMission: "REALISATION",
      },
    ],
    competence: ` 
        Contractant général,
        Travaux tout corps d'état,
        Expertise et ingénierie spécialisée en équipements sportifs,
        Ingénierie spécialisée en aménagements VRD,
        Ingénierie spécialisée en structure,
        Economie de la construction
        Accessibilité ERP,
        Parfaite connaissances des réglementations féférales,
        Conduite d'opérations, pilotage OPC,
        Marchés publics et Marchés privés,
        Conception environnementale et éco-responsable
      `,
    references: [
      {
        id: 1,
        urlImgCover: "/assets/agence_banner_3.png",
        urlVideo: "/assets/video/e-embauche.mp4",
        pays: "KOUNGOU",
        titre: "STADE ATHLETIQUE DE LONGONI",
        description:
          "création complète d'un stade athlétique 8 couloirs. construction d'un bâtiment vestiaires tribunes couvertes. Accessibilité du public et stationnement 80 000 m3 de terrassement sur 27 000 m2",
        prix: 15,
        unite: "M",
        year: 2018,
        projection: [
          {
            id_projection: 1,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
          {
            id_projection: 2,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
          {
            id_projection: 3,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
        ],
      },
      {
        id: 2,
        urlImgCover: "/assets/agence_banner_3.png",
        urlVideo: "/assets/video/e-embauche.mp4",
        pays: "KOUNGOU",
        titre: "STADE ATHLETIQUE DE LONGONI",
        description:
          "création complète d'un stade athlétique 8 couloirs. construction d'un bâtiment vestiaires tribunes couvertes. Accessibilité du public et stationnement 80 000 m3 de terrassement sur 27 000 m2",
        prix: 15,
        unite: "M",
        year: 2018,
        projection: [
          {
            id_projection: 1,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
          {
            id_projection: 2,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
          {
            id_projection: 3,
            photos: [
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
              "/assets/agence_banner_3.png",
            ],
          },
        ],
      },
    ],
  },
];

export const bannerImg = [
  "/assets/agence_banner_1.jpg",
  "/assets/agence_banner_3.png",
];

export const text_banner = `
          BIG a commencé à s'étendre au niveau mondiale et ne cessera de combler
          le manque dans chaque continent niveau construction et modélisation `;
