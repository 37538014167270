import "./EcoMateriaux.scss";
import { IoIosLeaf } from "react-icons/io";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import useStore from "@/store/store";

const EcoMateriaux = () => {
  const { ecoMateriaux } = useStore();
  return (
    <div className="EcoMateriaux">
      <img src="/assets/Eco-materiaux.png" alt="Eco materiaux illustration" />
      <div className="EcoMateriaux-content">
        <p>
          <IoIosLeaf className="leaf-icon" />
          <span>On respecte l'eco-responsabilité durant nos activités</span>
        </p>
        <h2>
          <FaQuoteLeft />
          <span>
            {ecoMateriaux &&
              Array.from(ecoMateriaux?.titre).map((letter, index) => (
                <span key={index} className="letter">
                  {letter}
                </span>
              ))}
            {/* <span className="letter">E</span>
            CO-MATERIAU<span className="letter">X</span> */}
          </span>
        </h2>
        <ul className="EcoMateriaux-liste">
          {ecoMateriaux?.liste.map((item, index) => (
            <li key={index}>
              <p>{index + 1}</p>
              <p>{item}</p>
            </li>
          ))}
        </ul>
        <FaQuoteRight className="right-icon" />
      </div>
    </div>
  );
};
export default EcoMateriaux;
