import React from "react";
import "./imgZoom.scss";
type Props = {
  blackCover?: boolean;
  className?: string;
  src: string;
  alt: string;
};
const ImgZoom = ({ className, src, alt, blackCover = false }: Props) => {
  return (
    <div className={`ImgZoom  ${blackCover ? "blackCover" : ""} ${className}`}>
      <div
        className={
          blackCover
            ? "absolute top-0 w-full h-full bg-black opacity-50"
            : "hidden"
        }
      />
      <img className={className} src={src} alt={alt} />
    </div>
  );
};
export default ImgZoom;
