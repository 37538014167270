import React, { useRef, useState } from "react";
import "./reference-detail-video-player.scss";
import { FiPlay, FiPause } from "react-icons/fi";
import ImgZoom from "../ImgZoom/ImgZoom";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  pays: string;
  videoLink: string;
};
const ReferenceDetailVideoPlayer = ({ pays, videoLink }: Props) => {
  const videoPlayerRef = useRef<HTMLVideoElement>(null);
  const [isPlay, setIsPlay] = useState(false);
  const playVideo = () => {
    if (!isPlay) {
      videoPlayerRef.current?.play();
    } else {
      videoPlayerRef.current?.pause();
    }
    setIsPlay((prev) => !prev);
  };
  return (
    <div className="ReferenceDetailVideoPlayer">
      <div className="ReferenceDetailVideoPlayer-videoCover">
        {
          <AnimatePresence>
            {isPlay && (
              <motion.video
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                src={videoLink}
                controls
                autoPlay
                key="video"
                className="h-full w-full object-cover  absolute top-0 left-0"
                ref={videoPlayerRef}
              />
            )}
            {!isPlay && (
              <motion.div
                key="imgCover"
                className="w-full h-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <ImgZoom
                  src="/assets/videoCover.png"
                  alt="video cover"
                  className="h-full w-full"
                  blackCover={true}
                />
              </motion.div>
            )}
          </AnimatePresence>
        }
        <div className="ReferenceDetailVideoPlayer-videoCover__btnPlay">
          {!isPlay ? (
            <FiPlay
              onClick={playVideo}
              color="white"
              fill="white"
              size="32px"
              className="translate-x-[.2rem] border-none"
            />
          ) : (
            <FiPause
              onClick={playVideo}
              color="white"
              fill="white"
              size="32px"
              className="border-none"
            />
          )}
        </div>
        <h1>{pays}</h1>
      </div>
    </div>
  );
};
export default ReferenceDetailVideoPlayer;
