import "./hero.scss";
import styles from "@/styles";
import { heroTitle } from "@/utils/text_content";
import { nav } from "@/utils/nav_content";
import { socialIcons } from "@/utils/icons";
import { MdMenu, MdClose } from "react-icons/md";
import { useState } from "react";
import { hero_image } from "@/utils/hero_img";
import { motion } from "framer-motion";
import { variants } from "@/utils/hero_motion";
import {
  TypedHeadingText,
  SlideInUpParagraphText,
} from "@/components/CustomText/CustomText";
import useStore from "@/store/store";

const Hero = () => {
  const { accueil } = useStore((state) => state);
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <section id="hero" className={`${styles.innerWidth} hero__container`}>
      <motion.div
        className="hero__container-header"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          type: "spring",
          duration: 1,
        }}
      >
        <img
          src="/assets/Logo BIG 2022.png"
          className="hero__container-header-logo"
          alt="logo"
        />
        <div className="hero__container-header__nav">
          {nav.map((item, index) => (
            <div className="hero__container-header__nav-item" key={index}>
              {item.icon}
              <span className="hero__container-header__nav-item-text">
                {item.title}
              </span>
            </div>
          ))}
        </div>
        <div className="md:hidden flex flex-col items-end relative">
          <button onClick={() => setToggleMenu((prev) => !prev)}>
            {toggleMenu ? (
              <MdClose className="text-[2rem]" />
            ) : (
              <MdMenu className="text-[2rem]" />
            )}
          </button>
          <div
            className={`flex flex-col items-end absolute top-8 bg-white px-4 py-2 border-[1px] rounded-sm ${
              toggleMenu ? "flex" : "hidden"
            }`}
          >
            {nav.map((item, index) => (
              <div className="hero__container-header__nav-item" key={index}>
                {item.icon}
                <span className="hero__container-header__nav-item-text">
                  {item.title}
                </span>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
      <div className="hero__container-body">
        <div className="hero__container-body__text-container">
          <TypedHeadingText title={heroTitle} />
          <SlideInUpParagraphText paragraph={accueil?.paragraphe1 as string} />
          <SlideInUpParagraphText paragraph={accueil?.paragraphe2 as string} />
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.3,
              duration: 0.5,
            }}
            className="hero__container-body__text-container-icon-container"
          >
            {socialIcons.map((icon, index) => (
              <div key={index}>{icon}</div>
            ))}
          </motion.div>
        </div>
        <div className="hero__container-body__img-container">
          {hero_image.map((item, i) => (
            <motion.div
              custom={i}
              animate="visible"
              variants={variants}
              initial="hidden"
              key={`hero_image${i}`}
              className="hero__container-body__img-container__content"
            >
              <img
                src={item}
                alt={`illustration ${i}`}
                className="hero__container-body__img-container__content-item"
              />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;
