import { Fragment } from "react";
import { motion } from "framer-motion";
import { text_content } from "@/utils/hero_motion";
type Props = {};

export const TypedHeadingText = ({ title }: { title: string }) => {
  return (
    <h1>
      {title.split("\n").map((line, lineIndex) => (
        <Fragment key={lineIndex}>
          {Array.from(line).map((item, index) => (
            <motion.span
              custom={index}
              variants={text_content}
              initial="hidden"
              animate="visible"
              key={index}
            >
              {item === " " ? "\u00A0" : item}
            </motion.span>
          ))}
          {lineIndex < title.split("\n").length - 1 && <br />}
        </Fragment>
      ))}
    </h1>
  );
};

export const TypedParagraphText = ({ paragraph }: { paragraph: string }) => {
  return (
    <p>
      {paragraph.split("\n").map((line, lineIndex) => (
        <Fragment key={lineIndex}>
          {Array.from(line).map((item, index) => (
            <motion.span
              custom={index}
              variants={text_content}
              initial="hidden"
              animate="visible"
              key={index}
            >
              {item === " " ? "\u00A0" : item}
            </motion.span>
          ))}
          {lineIndex < paragraph.split("\n").length - 1 && <br />}
        </Fragment>
      ))}
    </p>
  );
};

export const SlideInUpParagraphText = ({
  paragraph,
}: {
  paragraph: string;
}) => {
  return (
    <motion.p
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
    >
      {paragraph}
    </motion.p>
  );
};
