export const variants = {
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      delay: i * 0.1,
    },
  }),
  hidden: {
    opacity: 0,
    y: 500,
  },
};

export const text_content = {
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      ease: "easeIn",
      delay: i * 0.05,
      duration: 0.05,
    },
  }),

  hidden: {
    opacity: 0,
    y: 20,
  },
};
