import { Agence } from "@/interfaces/Agence";
import { Reference } from "@/interfaces/reference";
import { agences } from "./agence";

export class SingletonReferences {
  static references: Reference[];
  
  public static getReferences() {
    if (SingletonReferences.references === undefined) {
      SingletonReferences.references = (agences as Agence[])
        .map((agence) =>
          agence.references.map((reference) => ({
            ...reference,
            id: agence.titre + "-" + reference.id,
          }))
        )
        .flat(1);
      return SingletonReferences.references;
    } else {
      return SingletonReferences.references;
    }
  }
}
